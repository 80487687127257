/* @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400&family=Roboto+Mono:wght@100&family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200&family=Manrope:wght@200&family=Roboto+Mono:wght@100&family=Ubuntu&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/4.1.0/mdb.min.css"); */
/* @font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),url("../src/fonts/Montserrat-VariableFont_wght.ttf") format('truetype');
} */

@font-face{
  font-family:'Inter';
  src:local('Inter'),url("../src/fonts/Inter-VariableFont_slnt\,wght.ttf") format('truetype');
}


body {
  margin: 0;
  font-family: "Inter" sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cl-black {
  color: #000000 !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.bg-theme {
  background-color: #3b5998;
}

.bg-white,
.bg-white:hover,
.bg-white:focus {
  background-color: white !important;
}

.bg-theme:hover,
.bg-theme:focus {
  background-color: #5471ad;
}
.ml-0 {
  margin-left: 0 !important;
}

.btn {
  text-transform: none;
}

.cl-grey1 {
  color: #7f8790;
}

.bg-grey1 {
  background-color: #f2f2f2;
}

.bg-sec-blue {
  background-color: #3b5998 !important;
}

.cl-sec-blue {
  color: #3b5998 !important;
}
.cl-white {
  color: white !important;
}

.cl-base-blue {
  color: #174e86;
}
.bg-base-blue {
  background-color: #174e86;
}

.border-sec-blue {
  border: 1.55397px solid #49b7cf;
}
.border-none {
  border: none;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.border-right {
  border-right: 2px solid #e9e9e9;
}

.br-4 {
  border-radius: 4px !important;
}

.br-5 {
  border-radius: 5px !important;
}

.br-25 {
  border-radius: 25px !important;
}

.br-base-blue {
  border-color: #174e86 !important;
}

.vr-line {
  width: 7px;
  height: 27px;
  border-right: 2px solid #f2f0f0;
  margin-bottom: 0px !important;
}

.hr-line {
  width: 2px;
  height: 12px !important;
  border-right: 2px solid #f2f0f0;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-45 {
  margin-right: 2rem;
}

.ws-nowrap {
  white-space: nowrap;
}

.text-align-start {
  text-align: start;
}

.pl-0 {
  padding-left: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.pr-0 {
  padding-right: 0px !important;
}

.scb-none::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.white-space-nowrap {
  white-space: nowrap;
}

.btn-sh-none:hover,
.btn-sh-none:focus,
.btn-sh-none {
  box-shadow: none !important;
}

.bg-transparent {
  background: transparent !important;
}

.justify-content-right {
  justify-content: right !important;
}

.pe-none {
  pointer-events: none;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.make-text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vh-75 {
  height: 75vh;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%;
}
.w-20 {
  width: 20%;
}

.hover-large {
  transition: 1s;
}
.hover-large:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 10%);
}

.br-8 {
  border-radius: 8px !important;
}

.box-shadow {
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 5%);
}

.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}

.n-lh {
  line-height: 17px;
}

.btn-outline-primary {
  color: #49b7cf;
  border-color: #49b7cf;
}

.align-items-end {
  align-items: flex-end;
}

.pfs-12 {
  font-size: 12px !important;
}

@media (min-width: 767px) {
  .dv-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (max-width: 767px) {
  .mv-fs-5 {
    font-size: 5vw !important;
  }
  .mv-p-0 {
    padding: 0 !important;
  }
  .mv-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .mv-px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .mv-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .mv-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .mv-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mv-justify-content-center {
    justify-content: center !important;
  }

  .mv-text-align-center {
    text-align: center;
  }
  .mv-align-items-center {
    align-items: center !important;
  }
  .mv-align-items-start {
    align-items: start !important;
  }
  .mv-flex-wrap {
    flex-wrap: wrap;
  }
  .mv-d-flex {
    display: flex !important;
  }

  .mv-position-fixed {
    position: fixed;
  }
  .mv-position-absolute {
    position: absolute;
  }

  .mv-d-none {
    display: none !important;
  }
  .mv-d-block {
    display: block !important;
  }
  .mv-w-100 {
    width: 100% !important;
  }

  .mv-br-0 {
    border-radius: 0px !important;
  }
  .mv-h-50 {
    height: 50% !important;
  }
  .mv-h-auto {
    height: auto !important;
  }
  .mv-w-50 {
    width: 50% !important;
  }
  .mv-vh-75 {
    height: 75vh !important;
  }
  .mv-vw-100 {
    width: 100vw !important;
  }
  .mv-vw-90 {
    width: 90vw !important;
  }
  .mv-vw-80 {
    width: 80vw !important;
  }
  .mv-vh-100 {
    height: 100vh !important;
  }
  .mv-bg-white {
    background: white !important;
  }
  .mv-bs-none {
    box-shadow: none !important;
  }
  .mv-text-align-start {
    text-align: start !important;
  }
  .mv-flex-wrap {
    flex-wrap: wrap !important;
  }
  .mv-vh-50 {
    height: 50vh !important;
  }
  .mv-w-70 {
    width: 70%;
  }

  .mv-w-30 {
    width: 30%;
  }

  .mv-border-top {
    border-top: 1px solid rgb(217, 215, 215);
  }

  .mv-hr-line {
    width: 2.3px;
    height: 18px !important;
    border-right: 2px solid #f2f0f0;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  .mv-flex-row {
    flex-direction: row !important;
  }
  .mv-flex-col {
    flex-direction: column !important;
  }
}


.container-box{
  margin-left: 20vw;
  margin-top: 4.5rem;
  position: fixed;
  overflow-y: scroll;
  height: calc(100vh - 4.5rem);
}

.drop_menu{
  transform: translate3d(550px, 65px, 0px) !important;
}

.drop2_menu{
  transform: translate3d(50px, 65px, 0px) !important;
}