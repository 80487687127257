* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}

p {
    margin-bottom: 0;
}

.column {
    display: flex;
    grid-template-columns: 0.2fr 1fr;
    background: #fff;
}

.main {
    /* display: flex; */
    width: 80.5vw;
    display: grid;
    grid-template-columns: 1fr 0.3fr;
}

/* .body,
.aside { */
/* margin: 1em; */

/* } */


.head {
    margin-bottom: 1em;
}

.head .head-msg h1 {
    font-family: 'Manrope';
    font-style: normal;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
}

.head-filter {
    display: flex;
    justify-content: space-between;
}

.head-filter p {
    font-size: 0.85em;
    color: #9E9E9E;
}

.head-filter select {
    padding: 0.3em;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #000000;
    border: 1px solid #EFEFEF;
    border-radius: 20px;
}

/* COUNT */
.count {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
    margin-bottom: 1em;
}

.lead-count,
.ThreeD-count,
.quotation-count {
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    padding: 1em;

}

.lead-count h1,
.ThreeD-count h1,
.quotation-count h1 {
    text-align: center;
    font-weight: 700;
}

.lead-count p,
.ThreeD-count p,
.quotation-count p {
    color: #202020;
    font-weight: 600;
    font-size: 0.8em;
}

/* leads-3d-quotation */
.leads,
.ThreeD,
.quotation {
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    padding: 0.6em;
    margin-bottom: 1em;
}

.leads-title,
.ThreeD-title,
.quotation-title {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 0.8em;

}

.leads-title p,
.ThreeD-title p,
.quotation-title p {
    color: #202020;
}

.leads-title a,
.ThreeD-title a,
.quotation-title a {
    text-decoration: none;
    font-weight: 500;
    color: #176091;
    margin-right: 1.25rem !important;
}

.leads-body,
.ThreeD-body,
.quotation-body {
    padding: 1em;
    text-align: center;
}

.leads-body p,
.ThreeD-body p,
.quotation-body p {
    color: #176091;
    font-weight: 600;
    font-size: 1em;
}

/* active-plans */
.activePlans {
    background: rgba(23, 96, 145, 0.05);
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    padding: 0.5em;
    margin-bottom: 1em;
}

.activePlans p {
    font-size: 12px;
}

.activePlans .planUpgrade h6 {
    margin-bottom: 0;
}

.activePlans .planUpgrade a {
    text-decoration: none;
    font-size: 0.8em;
}

/* recentChats-recentOrders */
.recentChat,
.recentOrder {
    border: 1px solid #EFEFEF;
    border-radius: 15px;
    padding: 0.6em;
    margin-bottom: 1em;

}

.chatHead,
.orderHead {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;
}

.chatHead p,
.orderHead p {
    text-align: center;
    align-items: center;
}

.chatHead a,
.orderHead a {
    text-decoration: none;
}

.chatBody,
.orderBody {
    height: 15em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.chatBody p,
.orderBody p {
    color: #176091;
    font-weight: 500;
    padding: 0.5em;
    font-size: 1em;
}

/* set */
.setChatBody,
.setOrderBody {
    height: 15em;
}

.chat,
.order {
    border: 1px solid #EFEFEF;
    border-radius: 15px;
    padding: 0.75em;
    display: flex;
    justify-content: space-evenly;
    margin-top: 1em;
}

.chat img,
.order img {
    border-radius: 50%;
    border: 1px solid #EFEFEF;
}

.chatDetail h6,
.orderDetail h6 {
    margin-bottom: 0;
}

.orderDetail span {
    font-size: 0.75em;
    color: #9E9E9E;
}

.orderDetail p {
    font-size: 0.8em;
}

.order a {
    text-decoration: none;
    font-size: 0.75em;
}

.p-tag {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-left: 1rem;
    color: #000000;
}

.leads-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */


    color: #176091
}

.pp-haeder {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;

    color: #000000;
}

.plann {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;



    color: #000000;
}

.free {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;

    color: #000000;
}

.chats {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;

    color: #000000;

}