.container {
  /* border: 2px solid black; */
  background-color: #F7F7F7;
 
  /* height:65%; */
  margin-top: 0.5rem;
  margin-bottom: 4rem;
  /* overflow-y: scroll; */
  display: flex;
flex-direction: column;
gap: 2rem;
}
.head_div {
  /* border: 2px solid red; */
  display: flex;
  background-color: #ffffff;
  box-shadow: 0px 100px 256px rgba(154, 170, 207, 0.07),
    0px 41.7776px 106.951px rgba(154, 170, 207, 0.0503198),
    0px 22.3363px 57.181px rgba(154, 170, 207, 0.0417275),
    0px 12.5216px 32.0552px rgba(154, 170, 207, 0.035),
    0px 6.6501px 17.0243px rgba(154, 170, 207, 0.0282725),
    0px 2.76726px 7.08418px rgba(154, 170, 207, 0.0196802);
  border-radius: 8px 8px;
}

.heading_tab {
  width: 50%;
  /* border: 2px solid black; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #1c1b1f;
  padding-top: 0.8rem;
  padding-bottom: 0.6rem;
  cursor: pointer;
}
.heading_tab_Active {
  width: 50%;
  /* border: 2px solid black; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #1c1b1f;
  padding-top: 0.8rem;
  padding-bottom: 0.6rem;
  border-bottom: 2px solid #003566;
  cursor: pointer;
}

.content_div {
  /* border: 2px solid red; */
  height:16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}
.content_div_show {
  /* border: 2px solid red; */
  /* height: 85%; */
  overflow-y: scroll;
  /* margin-top: 1rem; */
  /* border: 1px solid grey; */
  box-shadow: 0px 100px 256px rgba(154, 170, 207, 0.07),
  0px 41.7776px 106.951px rgba(154, 170, 207, 0.0503198),
  0px 22.3363px 57.181px rgba(154, 170, 207, 0.0417275),
  0px 12.5216px 32.0552px rgba(154, 170, 207, 0.035),
  0px 6.6501px 17.0243px rgba(154, 170, 207, 0.0282725),
  0px 2.76726px 7.08418px rgba(154, 170, 207, 0.0196802);
border-radius: 12px;
padding-bottom: 2rem;
background-color: #ffffff;
}
.noProject {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;

  color: #bbbbbb;
}

.btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #003566;
  border-radius: 8px;
  margin-top: 1rem;
  padding: 0.7rem 1rem 0.7rem 1rem;
  border: none;
}

.admin_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #252525;
}

.place {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #888888;
  margin-top: 0.5rem;
}

.daysBtn {
  background: #eff5fb;
  border-radius: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #71a5de;
  border: none;
  height: 50%;
  padding: 0.1rem 1rem 0rem 1rem;
}
.daysBtnRed{
  background: #fceeee;
  border-radius: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #dd2c2f;
  border: none;
  height: 50%;
  padding: 0.1rem 1rem 0rem 1rem;
}
.lastUpdated {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #9aa4ac;
}

.threeBtnDiv1 {
  display: flex;
  justify-content: space-between;
  background: #f2f4f8;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #71a5de;
  width: 30%;
  padding: 0.6rem 1rem 0.6rem 1rem;
}
.threeBtnDiv2 {
  display: flex;
  justify-content: space-between;
  background: #fffae5;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #f5b82e;
  width: 30%;
  padding: 0.6rem 1rem 0.6rem 1rem;
}
.threeBtnDiv3 {
  display: flex;
  justify-content: space-between;
  background: #fceeee;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #dd2c2f;
  width: 30%;
  padding: 0.6rem 1rem 0.6rem 1rem;
}

.tableTabs {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(154, 170, 207, 0.2);
  border-radius: 4px;
  display: flex;
  gap: 3rem;
  /* border-bottom: 1px solid rgba(154, 170, 207, 0.2); */
 /* border: 2px solid red; */

}
.eachTab {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  color: #888888;
  padding: 1rem 2rem 1rem 2rem;
  
}
.eachTabActive{
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  color: #003566;
  font-weight: 600;
  padding: 1rem 2rem 1rem 2rem;
  transition: all 300ms;
  border-bottom: 3px solid #003566;
}
.eachCol {
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #808080;
  /* border-bottom:1px solid  #E6E6E6; */
}
