.container {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  height: 30rem;
}

/* .contentDiv{
    border: 2px solid red;
} */

.admin_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #252525;
}

.place {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #888888;
  margin-top: 0.5rem;
}

.daysBtn {
  background: #eff5fb;
  border-radius: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #71a5de;
  border: none;
  height: 50%;
  padding: 0rem 1.7rem 0rem 1.7rem;
}
/* .daysBtnRed {
  background: #fceeee;
  border-radius: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #dd2c2f;
  border: none;
  height: 50%;
  padding: 0.1rem 1rem 0rem 1rem;
} */
.lastUpdated {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #9aa4ac;
}

.tableDiv {
  margin-top: 1rem;
}

.tableTabs {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(154, 170, 207, 0.2);
  border-radius: 4px;
  display: flex;
  gap: 3rem;

  /* border-bottom: 1px solid rgba(154, 170, 207, 0.2); */
  /* border: 2px solid red; */
}
.eachTab {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  color: #888888;
  padding: 0.6rem 0 0.6rem 1rem;
  /* padding: 1rem 2rem 1rem 2rem; */
}
.eachTabActive {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  color: #003566;
  font-weight: 600;
  /* padding: 1rem 2rem 1rem 2rem; */
  transition: all 300ms;
  padding: 0.6rem 0 0.6rem 1rem;
  border-bottom: 3px solid #003566;
}

.contentDiv {
  padding: 1rem;
}

.smallText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.104348px;

  color: #212121;
  display: flex;
  flex-direction: column;
}

.date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.104348px;

  color: #4d4d4d;
}

.latestText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.104348px;

  color: #000000;
}

.viewAll {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.104348px;
  text-decoration-line: underline;
  cursor: pointer;
  color: #1d3557;
}

.progress {
  width: 100%;
  height: 0.7rem;
  background: #f2f2f2;
  border-radius: 12px;
  margin-top: 0.5rem;
}

.progressChild {
  width: 30%;
  height: 0.7rem;
  background-color: #65aac8;
  border-radius: 12px;
}
