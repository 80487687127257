.container {
  /* border: 2px solid black; */
  padding-bottom: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #212121;
}
.para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: #212121;
  margin-top: 0.5rem;
}

.Eachimg{
    width: 30%;
}
