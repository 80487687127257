.headerLinksInactive {
  margin-right: 3rem;
  cursor: pointer;
  padding-bottom: 2.8vh;
  position: relative;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #444444;
}

.headerLinksActive {
  padding-bottom: 2.3vh;
  border-bottom: 2px solid #3b5998;
  color: #3b5998;
  cursor: pointer;
  margin-right: 3rem;
  font-weight: 500;
  position: relative;
}

.planType {
  font-size: 10px;
  border-radius: 50px;
  background-color: #17609126;
  padding: 0 1rem;
}

.dropDownLinks {
  color: #5c5c5c !important;
}

.commCount {
  height: 15px;
  width: 15px;
  font-size: 10px;
  background: #fb5622;
  color: white;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: flex-start;
  position: absolute;
  right: -0.5rem;
  top: -0.35rem;
}
