.container {
  margin-bottom: 5rem;
  /* border: 2px solid red; */
}
.contain {
  height: 35vh;
  background: #f1fafd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btnn1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 127.52%;
  background-color: #ffffff;
  text-align: center;
  border-radius: 9px;
  color: #1d3557;
  border: none;
  padding: 0.6rem 1rem 0.6rem 1rem;
  background: #ffffff;
  box-shadow: 0px 6px 8px rgba(154, 170, 207, 0.2);
  box-shadow: 1px 1px 2px 2px rgba(154, 170, 207, 0.2);
}

.btn2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 127.52%;
  text-align: center;
  color: #ffffff;
  background-color: #1d3557;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 9px;
  margin-left: 1rem;
  border: none;
}

.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 50px;
  /* identical to box height */

  color: #132339;
}
.para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  color: #132339;
}

.scheduleHead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 127.52%;
  color: #222222;
  padding-top: 4rem;
}

.requestHead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 127.52%;
  color: #212121;
  margin-top: 2rem;
}

.eachLine {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 127.52%;
  color: #141414;
  margin-top: 1.5rem;
}

.scheduleDemo {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 127.52%;
  color: #212121;
  margin-bottom: 1.2rem;
  
}

.inputsDrop{
  border-radius: 10px;
  padding: 0.5rem;
  font-size: 16px;

}


.outlinedTextField {
  display: inline-flex;
  align-items: center;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  padding: 4px;
}

.input {
  width: 100%;
  border: none;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
}

.label {
  position: absolute;
  top: -8px;
  left: 12px;
  background-color: #fff;
  padding: 2px;
  font-size: 14px;
  font-weight: bold;
  color: #bdbdbd;
}
.menu .modal{
  z-index: 1065 !important;
}
