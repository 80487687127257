.container {
  /* border: 2px solid red; */
  height: 90vh;
  display: flex;
}

.firstSection {
  /* border: 2px solid black; */
  width: 65%;
  padding-left: 5rem;
}
.Check_heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  /* identical to box height */
  margin-bottom: 1rem;
  margin-top: 2rem;
  /* border: 2px solid black; */
  color: #212121;
}
.first_div {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  padding-left: 2rem;
  padding-top: 1.2rem;
  padding-right: 2rem;
  box-shadow: 0px 4px 8px rgba(154, 170, 207, 0.2);
  border-radius: 12px;
  width: 100%;
  height: 16rem;
  /* border: 2px solid red; */
}
.sec_div {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  padding-left: 2rem;
  padding-top: 1.2rem;
  padding-right: 2rem;
  box-shadow: 0px 4px 8px rgba(154, 170, 207, 0.2);
  border-radius: 12px;
  width: 100%;
  height: 16rem;
  margin-top: 2rem;
  /* border: 2px solid red; */
}
.div_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #252525;
  /* border: 2px solid red; */
}
.inputs {
  margin-top: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 7px;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.inputs1 {
  margin-top: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 7px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  width: 50%;
}

.secondSection {
  /* border: 2px solid black; */
  width: 35%;
  display: flex;
  /* justify-content: center; */
  padding-top: 5.7rem;
  padding-left: 2rem;
}

.summary {
  background: #ffffff;
  border: 1px solid #e6e6e6;

  box-shadow: 0px 4px 8px rgba(154, 170, 207, 0.2);
  border-radius: 12px;
  height: 28rem;
  width: 25rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
}
.sum_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  color: #252525;
  padding-bottom: 2rem;
}

.sub_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  /* margin-bottom: 2rem; */
  color: #212121;
}

.pro_btn {
  background: #f1fbfd;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #2053c5;
  margin-bottom: 1rem;
}
.proImg {
  height: 1.1rem;
  /* width: 4rem; */
}

.btn {
  padding: 0.5rem 2rem 0.5rem 2rem;
  background: #2053c5;
  border-radius: 9px;
  border: none;
  font-weight: 600;
  color: #ffffff;
  margin-top: 2rem;
}

.foot_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  text-align: center;
  color: #a8a8a8;
  margin-top: 1rem;
}
