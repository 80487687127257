.heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    margin-top: 10px;
    color: #000000;
}

.sub-heads {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;

}

.lead_main {
    margin-left: 10px;
    margin-top: 5px;
    width: 95%;

    /* background-color: red; */
}

.main_content {
    background-color: #ffffff;
    width: 100%;
    border-bottom: 1px solid #DFDFDF;
    padding: 0.5rem;


}

.name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #000000;
}

.time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;

    color: #888888
}

.pricee {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #888888;

}

.roomss {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #888888;

}

.vieww {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-decoration-line: underline;
    color: #3B5998;
}

.paisa {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    margin-top: 5px;
}

.sub-heads_mob {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}

.measure_down {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: right;

    color: #000000;
}

.pay {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #26AA63;
    margin-left: 5px;

}

.active {
    color: #3B5998;
    border-bottom: 1px solid #3B5998;
    font-weight: 500;
}

.unactive {
    color: #888888;
    font-weight: 400;
}

.download {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #3B5998;
    margin-top: 10px;
}

.paynow {
    color: #3B5998;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-top: 6px;
    text-decoration-line: underline;
}

.feedback {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #000000
}

.text {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #898282;
    width: 34vw;
    height: 20vh;
    border-radius: 8px;
    border: 1px solid #A7A7A7;
    padding-top: 15px;
    padding-left: 15px;
    margin-top: 10px;
}

.number {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #888888;
    margin-Left: auto;
    width: 15%;


}

.buttonn {
    background: #2F4776;
    border-radius: 6px;
    border: none;
    color: #ffffff;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    padding: 10px 20px;
}

.nnumber {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #888888;
    margin-Left: auto;
    width: 20%;
}


.but_n{
    border:none;
    background: #FFFFFF;
    border: 1px solid #3B5998;
    border-radius: 12px;
    width: 24%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #3B5998;
}