.container{
 margin-bottom: 8rem;
    position: relative;
    /* border: 2px solid red; */
    background-color: #ffffff;
    padding-top: 1rem;
    padding-bottom: 1rem;
 
}

.files_contain {
    /* border: 2px solid black; */
    /* height: 44vh; */
    display: flex;
    justify-content: space-between;

  }
  
  .img_div {
    /* border: 2px solid red; */
    width: 50%;
    position: relative;
  }
  .file_content_div {
    /* border: 2px solid red; */
    width: 50%;
    display: flex;
    flex-direction: column;
  justify-content: center;
    width: 50%;
    padding-left: 4rem;
    padding-right: 3rem;
  }
  
  .file_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 127.52%;
    color: #1D3557;
  }
  .file_para {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;   
    color: #4D4D4D;
    margin-top: 0.5rem;
  }

  .iconDiv {
    /* border: 2px solid red; */
    width: 90%;
    margin-top: 3.5rem;
    display: flex;
   gap: 4rem;
  }
  
  .eachBox{
    /* border: 2px solid red; */
    width: 100%;
  }
  
  .icon{
    height: 2.5rem;
    width: 2.5rem;
   
  }
  
  .icon_para{
    font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(33, 33, 33, 0.8);
  margin-top: 1rem;
  }
  .mainImg{

    width: 92%;

  }
  /* .back{
    width: 55%;
    height: 35rem;
  } */