.createNewButton button {
    background-color: #3b5998;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    width: 94%;
    font-size: 14px;

}

.createNewButton {
    width: 100%;
}

.inputField input {
    width: 100%;
    border-radius: 8px;
    height: 2.5rem;
    padding: 1rem;
    border: 1px solid #dfdfdf;
}

.modalBodyScrollable {
    overflow-y: scroll;
}

.modalContinueActive {
    background-color: #3B5998;
    border-radius: 8px;
    color: #ffffff;
    padding: 0.5rem 1.5rem;
    border: none;
    user-select: none;
}

.modalContinueInactive {
    background-color: #F0F0F0;
    border-radius: 8px;
    color: #888888;
    padding: 0.5rem 1.5rem;
    border: none;
    user-select: none;
    pointer-events: none;
}

.customCheckboxContainer {
    display: flex;
    align-items: center;
    margin: 2rem 0 2.5rem 0;
}

.customCheckbox {
    background-color: #176091;
    height: 1.2rem;
    width: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    border-radius: 2px;
    margin-right: 0.6rem;
    cursor: pointer;
}

.customCheckboxUnchecked {
    background-color: #ffffff;
    height: 1.2rem;
    width: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    margin-right: 0.6rem;
    border: 1px solid #dfdfdf;
    cursor: pointer;
}

.inputSection {
    position: relative;
    margin: 1rem 0;
    user-select: none;
}

.inputSection div {
    font-size: 14px;
    color: #a7a7a7;
    margin-left: 0.5rem;
    position: absolute;
    bottom: 39px;
    background-color: #ffffff;
    padding: 0 0.5rem;
}

.inputSection input {
    height: 3rem;
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid #dfdfdf;
    width: 100%;
}

.clientCardContainer {
    border: 1px solid #dfdfdf;
    margin: 0.5rem 0;
    border-radius: 5px;
    padding: 0.5rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.clientCardContainer:hover {
    border: 2px solid #43a6dd;
}

.clientCardContainerActive {
    border: 2px solid #43a6dd;
    margin: 0.5rem 0;
    border-radius: 5px;
    padding: 0.5rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.initials {
    background-color: #43a6dd;
    border-radius: 50%;
    color: #ffffff;
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    margin-right: 1rem;
}