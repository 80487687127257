.cardContainer {
  background-color: #ffffff;
  padding: 1rem;
  margin-bottom: 0.5rem;
}
.firstLine {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.initialsAndName {
  display: flex;
}
.initialsCircle {
  background-color: #f0f0f0;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888888;
  margin-right: 0.5rem;
}
.nameAndLocation {
  display: flex;
  flex-direction: column;
}
.leadName {
  font-weight: 500;
  font-size: 16px;
}
.leadLocation {
  font-size: 12px;
  color: #888888;
}
.timelinePill {
  background-color: #e2e6f0;
  border-radius: 20px;
  font-size: 12px;
  color: #3b5998;
  padding: 0 0.5rem;
  width: fit-content;
}

.secondLine {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid #dfdfdf;
}
.secondLineHeading {
  font-size: 12px;
  color: #888888;
}
.secondLineSubheading {
  color: #000000;
  font-weight: 400;
  font-size: 14px;
}

.thirdLine {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}
.leadPrice {
  font-size: 18px;
  font-weight: 500;
}
.buyButtons {
  display: flex;
}
.addToCartButton{
    border-radius: 20px;
    border: 1px solid #3b5998;
    background-color: #ffffff;
    color: #3b5998;
    padding: 0 1.5rem;
    margin-right: 0.5rem;
}
.addToCartButtonActive{
  border-radius: 20px;
  border: 1px solid #3b5998;
  background-color: #3b5998;
  color: #ffffff;
  padding: 0 1.5rem;
  margin-right: 0.5rem;
}
.buyButton{
    border: 1px solid #3b5998;
    background-color: #3b5998;
    color: #ffffff;
    border-radius: 20px;
    padding: 0 1.5rem;
    font-size: 14px;
}
