.container {
  height: 100vh;
  display: flex;
}
.first_contain {
  background-color: #e9effb;
  height: 100vh;
  width: 50%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}

.contentDiv {
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  height: 60%;
}

.top_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;

  color: #2053c5;
}
.iconsDiv {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
}
.iconImg {
  height: 3.5rem;
  width: 15rem;
  margin-top: 3rem;
  /* border: 2px solid red; */
}
.para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  /* line-height: 27px; */
  color: #212121;
  margin-top: 0.5rem;
}
.icon {
  margin-top: 2.5rem;
  height: 1.5rem;
  width: 1.5rem;
}

/* second div */
.second_contain {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
}
.PersonalContent_div {
  /* border: 2px solid red; */
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  padding-left: 5rem;
}
.personal_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  color: #212121;
}
.inputs {
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.8rem 1rem 0.8rem 1rem;
  width: 80%;
  margin-top: 2rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;

  color: #2053c5;
  color: #7a7a7a;
}
.inputs1 {
  background: #eafaee;
  border-radius: 10px;
  border: none;
  padding: 0.8rem 1rem 0.8rem 1rem;
  width: 80%;
  margin-top: 2rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: #2dc653;
}
.inputs ::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #7b7878;
}

.skip {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #b7b8bb;
  display: flex;
  justify-content: center;
  /* border: 2px solid red; */
  width: 80%;
  margin-top: 1rem;
  cursor: pointer;
}
.check {
  width: 15px;
  height: 15px;
  margin-right: 0.5rem;
}
.backArrow {
  margin-left: 0.5rem;
}
.btn {
  background: #2053c5;
  border-radius: 10px;
  border: none;
  padding: 0.9rem 1rem 0.9rem 1rem;
  width: 80%;
  margin-top: 2rem;
  color: #ffffff;
}
.backBtn {
  background: #f6f8fe;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #2053c5;
  border: none;
  border-radius: 14px;
  padding: 0.2rem 1rem 0.2rem 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.backArrow{
  margin-right: 0.5rem;
}
.uploadImg_div {
  background: #f6f8fe;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 50%; */
  padding: 4rem 0 4rem 0;
  width: 80%;
 /* border: 2px solid red; */
 cursor: pointer;
}
.img_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  color: #b6bbcc;
  text-align: center;
}
.uploadImg_head {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #4d4d4d;
}
.cloudImg{
 height: 6rem;
}
