.btn {
  background: #e9e6ff;
  border-radius: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #9381ff;
  padding: 0.4rem 1rem 0.4rem 1rem;
  border: none;
}
 .eachCol {
  /* border: none; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #808080;
  width: 16%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* border: 2px solid red; */
}

td{
  width: 14%;
  /* border: 2px solid red; */
}