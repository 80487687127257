.container {
  /* border: 2px solid red; */
margin-bottom: 8rem;
}
.contain {
  background: #f6fcfe;
  height:42vh;
  display: flex;
  /* border: 2px solid black; */
}
@media only screen and (max-width:1300px){
  .container {
    /* border: 2px solid red; */
    height: 65vh;
  }
  .contain{
    background: #f6fcfe;
    height:50vh;
    display: flex;
    flex-direction: column;
    padding-left: 4rem;
    /* border: 2px solid green; */
  }
 
.boxes{
  /* border: 2px solid black; */
  display: flex;
  /* justify-content: center; */
  gap: 1.5rem;
  align-items: center;
  width:fit-content;
  
}
}

.boxes {
  /* border: 2px solid red; */
  padding-left: 8rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  width:75%;
  /* padding-left: 8rem; */
}

.text_div {
  width: 20%;
  display: flex;
  justify-content:flex-end;
  align-items: center;

  /* border: 2px solid red; */
}
.text_div1 {
  width: 20%;
  display: flex;
  justify-content:flex-start;
  width: 100%;
  align-items: center;
  padding-left: 1.6rem;
  padding-top: 1rem;
  margin-bottom: 2rem;
  /* border: 2px solid red; */
}
.text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #1d3557;
}


.imgs {
  height: 17rem;
width: 100%;
  cursor: pointer;
  /* border: 2px solid red; */
}
