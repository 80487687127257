.container {
  background: linear-gradient(180deg, #EBF7F7 0%, rgba(235, 247, 247, 0) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 8rem;
}

.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  color: #000000;
  text-align: start;
  /* border: 2px solid red; */
padding-bottom: 1rem;
}

.para {
  margin-top: 1rem;
  width: 55%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: #000000;
}
