.eachCol {
  /* border: none; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #808080;
  /* width: 16%; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* border: 2px solid red; */
}


.statusBtn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1px;
  background-color: #F2F2F2;
  border-radius: 50px;
  color: #acacac;
  border: none;
  padding: 0.2rem;
  width: 60%;
  /* border: 2px solid red; */
}
