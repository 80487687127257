.container {
  /* border: 2px solid black; */
  margin-bottom: 5rem;
  width: 100%;
  position: relative;
}
.btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #7b2cbf;
  padding: 0.4rem 1rem 0.4rem 1rem;
  border: none;
  background: #f3eafa;
  border-radius: 50px;
  margin-top: 4rem;

  /* border: 2px solid red; */
}
.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: #1d3557;
  margin-top: 1rem;
  /* width: 50%; */
  /* border: 2px solid red; */
}
.content_div {
  /* border: 2px solid red; */
  width: 50%;
  /* width: 50%; */
}
.projects_div {
  height: 40vh;
  width: 70%;
  margin-top: 2rem;
}
.file_timeline {
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;

}
.task_daily {
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
}
.icon_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  padding-top: 0.6rem;
  line-height: 22px;

  color: #212121;
}
.para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4d4d4d;
  margin-top: 0.2rem;
}
.img_div {
  /* border: 2px solid blue; */
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.mainImg {
  position: absolute;
  top: 10%;
  right: 7%;
  height:40rem;
  width: 45%;
  border-radius: 16px;
  /* border: 2px solid red; */
}
.back {
  /* border: 2px solid red; */
  width: 63%;
  height: 39rem;
}
