.fullContainer {
  width: 82vw;
}

.fullHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  padding: 1rem;
}
.mainHead {
  font-size: 18px;
  font-weight: 600;
}
.fullHeadingDropdown {
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  color: #888888;
  border-radius: 36px;
  font-size: 12px;
  padding: 0.25rem 1rem;
}

.buyLeadsCardsContainer {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 82vw;
  overflow-y: scroll;
  height: 79vh;
}
.buyLeadsCardsContainer::-webkit-scrollbar{
  display: none;
}
.head1 {
  font-size: 16px;
  font-weight: 500;
}
.infoDialogBubble {
  display: flex;
  align-items: flex-end;
}
.infoDialogBubble img {
  height: 3.5rem;
  margin-right: 0.8rem;
}
.infoDialogBubble div {
  color: #3b5998;
  font-weight: 600;
}
.dialogBox{
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0.5rem 1rem 0.5rem 1.5rem;
    width: 20rem;
    border-radius: 4px;
    font-size: 12px;
}
.sectionHeading{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.allCardContainer{
  margin-bottom: 2.5rem;
}

.nothingToShow{
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  color: #888888;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 2.5rem;
}
