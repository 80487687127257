.contain {
  /* border: 2px solid black; */
  margin-bottom: 4rem;
}
.testi_heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 50px;
  text-align: center;
  color: #1d3557;
}
.head_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 127.52%;
  /* or 26px */
  text-align: center;

  color: #252525;
  width: 100%;
  /* border: 2px solid red; */
  padding-bottom: 1rem;
}

.testi_contain {
  width: 19rem;
  height: 19rem;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  padding: 1rem 0.5rem 1rem 1rem;
  overflow-y: scroll;
  /* margin-left:1rem; */
  /* border: 2px solid red; */
}
.pics {
  /* border: 2px solid red; */
  height: 6rem;
  width: 6rem;
}
.name {
  /* border: 2px solid red; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #212121;
  padding-top: 0.6rem;
}

.person_job {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #4d4d4d;
  margin-top: 0.5rem;

  /* border: 2px solid red; */
}

.long_text {
  /* border: 2px solid red; */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 149.9%;
  color: rgba(33, 33, 33, 0.75);
  margin-top: 1rem;
}
