.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
}
.first_contain {
  background-color: #e9effb;
  /* height: 100vh; */
  padding-top: 5rem;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  /* border: 2px solid red; */
}

.ImgDiv {
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e9effb;
}
.imgg {
  width: 80%;
}

.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 127.52%;
  text-align: center;
  color: #1d3557;
  padding-top: 1rem;
}
.para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #212121;
  margin-top: 0.4rem;
}
.icon {
  margin-top: 2rem;
  height: 1.5rem;
  width: 1.5rem;
}

/* second div */
.second_contain {
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  /* border: 2px solid red; */
}
.PersonalContent_div {
  /* border: 2px solid red; */

  display: flex;
  flex-direction: column;
   /* border: 2px solid red; */
   width: 90%;
}
.personal_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #212121;
}
.inputs {
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.6rem 1rem 0.6rem 1rem;
  margin-top: 1rem;
 
}
.inputsDrop{
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.3rem 1rem 0.3rem 0.4rem;
  margin-top: 1rem;
  color: #4D4D4D;
}
.inputsErr {
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.6rem 1rem 0.6rem 1rem;
   margin-top: 1rem;
  border: 2px solid rgb(190, 48, 48);
  color: #4D4D4D;
}
.inputsErrDrop {
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.3rem 1rem 0.3rem 0.4rem;
  margin-top: 1rem;
  border: 2px solid rgb(190, 48, 48);
}
.inputs::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #4d4d4d;
}
.btn {
  background: #2053c5;
  border-radius: 10px;
  border: none;
  padding: 0.5rem 1rem 0.5rem 1rem;
  width: 100%;
  margin-top: 2rem;
  color: #ffffff;
}
.backBtn {
  background: #f6f8fe;
  /* border-radius: 50px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* line-height: 24px; */
  color: #2053c5;
  border: none;
  border-radius: 14px;
  padding: 0.2rem 1rem 0.2rem 1rem;
}
.backArrow {
  margin-right: 0.5rem;
}

.skip {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #b7b8bb;
  display: flex;
  justify-content: center;
  /* border: 2px solid red; */
   text-align: center;
  margin-top: 0.8rem;
  margin-bottom: 2rem;
  cursor: pointer;
}
