.fullContainer {
  background-color: #f0f0f0;
  height: 90vh;
}

.pageHeading {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  align-items: center;
}
.mainHead {
  font-size: 18px;
  font-weight: 500;
}
.filterButtonContainer {
  display: flex;
}
.filterButtons {
  font-size: 12px;
  border: 1px solid #dfdfdf;
  border-radius: 36px;
  padding: 0 0.5rem;
}

.budgetFilterOffcanvas {
  border-radius: 8px 8px 0 0 !important;
  height: fit-content !important;
}
.filterHeader {
  display: flex;
  justify-content: center !important;
  font-size: 48px;
}
.closeBar {
  height: 10px;
  width: 32%;
  background-color: #f5f5f5;
  border-radius: 20px;
}
.budgetFilterBody {
  padding: 0.5rem 1rem !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.eachFilterButton {
  padding: 0.5rem;
}

.allSectionsContainer {
  overflow-y: scroll;
  height: 83vh;
}
.justLandedContainer {
  display: flex;
  flex-direction: column;
}
.justLandedContainerHeading {
  padding: 0.5rem;
  background-color: #ffffff;
  margin: 0.5rem 0;
}
.sectionHeading {
  display: flex;
}
.sectionHeading img {
  height: 3.5rem;
  margin-right: 0.5rem;
}
.sectionTextHead {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.sectionHeading div {
  font-size: 12px;
}
.dialogBox {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  color: #3b5998;
  padding: 0.7rem 0.7rem 0 1.2rem;
}
.cardsContainer {
  display: flex;
  flex-direction: column;
}

.nothingToShow {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888888;
  border: 1px solid #dfdfdf;
  padding: 2rem;
  background-color: #ffffff;
}
