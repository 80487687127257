.fullContainer {
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  margin-bottom: 0.5rem;
}

.firstLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.initialsAndName {
  display: flex;
  align-items: center;
}
.initialsCircle {
  background-color: #9c77ec;
  border-radius: 50%;
  height: 2.2rem;
  width: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-right: 0.5rem;
}
.leadName {
  font-size: 14px;
  font-weight: 500;
}
.locationAndDays {
  display: flex;
  align-items: center;
  font-size: 10px;
  color: #888888;
}

.secondLine {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid #dfdfdf;
}

.sectionHead {
  font-size: 10px;
  color: #888888;
}
.sectionSubHead {
  font-size: 14px;
  font-weight: 500;
}

.thirdLine {
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
  align-items: center;
}
.leadBucketSelector {
  border-radius: 32px;
  border: 1px solid #79b3f7;
  color: #79b3f7;
  background-color: #ffffff;
  padding: 0.25rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  width: 7rem;
}

.actionsContainer {
  display: flex;
}

.chatAction {
  margin-right: 0.8rem;
  background-color: #eff4ff;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3d5a99;
}
.callAction {
  background-color: #eff4ff;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3d5a99;
}

.closeBar {
  height: 10px;
  width: 32%;
  background-color: #dfdfdf;
  border-radius: 20px;
}

.bucketCanvas {
  padding: 1rem !important;
}
.eachBucket {
  color: #575757;
  padding: 0.25rem 0;
  margin-bottom: 0.25rem;
}

.eachControlsButton {
  color: #575757;
  padding: 0.25rem 0;
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
}
.eachControlsDelete {
  color: #ff7f56;
  padding: 0.25rem 0;
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
}
.eachControlsMoveTo {
  color: #888888;
  padding: 0.25rem 0;
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
