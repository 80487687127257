.scheduleHead {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 127.52%;
    color: #222222;
    padding-top: 4rem;
  }
  
  .requestHead {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 127.52%;
    color: #212121;
    margin-top: 2rem;
  }
  
  .eachLine {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 127.52%;
    color: #141414;
    margin-top: 1.5rem;
  }
  
  .scheduleDemo {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 127.52%;
    color: #212121;
    margin-bottom: 1.2rem;
    
  }
  
  .inputsDrop{
    border-radius: 10px;
    padding: 0.5rem;
    font-size: 16px;
  
  }
  
  
  .outlinedTextField {
    display: inline-flex;
    align-items: center;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    padding: 4px;
  }
  
  .input {
    width: 100%;
    border: none;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 4px;
  }
  
  .label {
    position: absolute;
    top: -8px;
    left: 12px;
    background-color: #fff;
    padding: 2px;
    font-size: 14px;
    font-weight: bold;
    color: #bdbdbd;
  }