.container {
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
  margin-left: 2rem;
  margin-right: 5rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
}
.text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.138043px;
  color: #9aa4ac;
}

.totalAmount {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */

  color: #4d4d4d;
}
