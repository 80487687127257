.leadCardContainer {
  border-bottom: 1px solid #dfdfdf;
  padding: 0.5rem 0;
  white-space: nowrap;
}
.leadDetails {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 4px 4px 0 0;
  font-size: 13px;
}

.checkboxSection {
  width: 2%;
  display: flex;
  align-items: center;
}
.customCheckbox {
  border: 1px solid #888888;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.customCheckboxActive {
  border: 1px solid #3b5998;
  background-color: #3b5998;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.clientNameSection {
  width: 13%;
  padding-right: 0.5rem;
}
.citySection {
  width: 13%;
  padding-right: 0.5rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.dateSection {
  width: 10%;
}
.budgetSection {
  width: 10%;
}
.workTypeSection {
  width: 10%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sourceSection {
  width: 7%;
}
.phNumSection {
  width: 10%;
  color: #3b5998;
  text-decoration: underline;
  cursor: pointer;
}
.statusSection {
  width: 15%;
}
.statusDropdown {
  font-size: 10px;
  border-radius: 32px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0.8rem;
  align-items: center;
  border: 1px solid #79b3f7;
  color: #79b3f7;
  background-color: #ffffff;
}
.actionSection {
  width: 10%;
  display: flex;
  justify-content: space-around;
  color: #888888;
  align-items: center;
}
.chatIcon {
  background-color: #ffffff;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888888;
  padding: 0.25rem;
  cursor: pointer;
}
.chatIcon:hover {
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  cursor: pointer;
  box-shadow: 0 4px 10px #c4c4c4;
  transition: all;
  transition-duration: 250ms;
}

.eachControlsButton {
  color: #888888;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
}
.eachControlsMoveTo {
  color: #000000;
  padding: 0.25rem 0;
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
