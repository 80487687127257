.new-pricing-main {
  justify-content: center;
  align-items: center;
}

.newpricingheader22 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;

  color: #1d3557;
}

.newpricingspan {
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: #444444;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 127.52%;
  /* identical to box height, or 26px */

  text-align: center;
  margin-top: 0.5rem;
  color: #252525;
}

.toggleactive {
  background: #1b3f7c;
  border-radius: 50px;
  font-weight: 500;
  font-size: 13px;
  color: #ffffff;
  padding: 0.22rem 0.65rem;
  cursor: pointer;
  transition: all 300ms;
}

.toggleunactive {
  transition: all 300ms;
  background: transparent;
  border-radius: 50px;
  font-weight: 400;
  font-size: 13px;
  color: #1d3557;
  padding: 0.28rem 0.5rem;
  cursor: pointer;
}
.Mainboxdiv {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  gap: 1rem;
  align-items: center;
  padding-top: 2rem;
  overflow-x: scroll;
}
.box1 {
  border: 2px solid black;
  width: 84vw;
  min-height: 40rem;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 16px;
  position: relative;
  /* border: 2px solid red; */
}
.box2 {
  border: 2px solid black;
  width: 84vw;
  min-height: 40rem;
  border: 1px solid #e6e6e6;
  border-radius: 16px;
  background-color: #1b3f7c;
  color: #ffffff;
  position: relative;
}

.pro_img {
  width: 20px;
  height: 20px;
}

.pro_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  text-transform: uppercase;
  border: 1px solid #2053c5;
  color: #2053c5;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  border-radius: 12.874px;
  background-color: #ffffff;
  margin-left: 0.5rem;
}

.unlimited_users {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1rem;
  padding-right: 1rem;
}
.unlimited_userss {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1rem;
  padding-right: 1rem;
}
.unlimited_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;
  padding: 0.3rem;
  color: #2053c5;
  background: #d8edfd;
  border-radius: 4px;
  border: none;
  cursor: auto;
}
.unlimited_btnn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;
  padding: 0.3rem;
  color: #2053c5;
  background: #ffffff;
  border-radius: 4px;
  border: none;
  cursor: auto;
}

.basic {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  color: #272727;
  /* border: 2px solid red; */
}
.rupees {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 48px;
}
.price_btn {
  background: #3e96ed;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;

  color: #ffffff;
  border: none;
  padding: 0.6rem 2rem 0.6rem 2rem;
  width: 90%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.client_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  text-transform: uppercase;

  color: #272727;
  margin-top: 1rem;
}

.client_subset {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12.88px;
  line-height: 25px;
  /* identical to box height */

  color: #000000;
}

.nineFeature {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12.88px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;

  color: #8ac0f4;
}

.third_img_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  text-align: center;

  color: #ffffff;
  border-top: 1px solid rgba(216, 237, 253, 0.5);
  padding-top: 2rem;
  text-align: center;
  /* border: 2px solid red; */
}
.foot {
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  /* identical to box height */

  color: #000000;
  padding: 2rem 2rem 1rem 2rem;
  position: absolute;
  bottom: 0;
}
