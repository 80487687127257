.ven_head {
    font-weight: 600;
    font-size: 24px;
    color: #000000;
}

.ven_sp {
    font-weight: 400;
    font-size: 14px;
    color: #444444;
}

.list_head {
    font-weight: 600;
    font-size: 22px;
    color: #000000;
}

.list_start {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #444444;
}

::marker {
    font-size: 16px !important;
    color: #444444 !important;
}

.ven_btn {
    font-weight: 300;
    width: 24%;
    background: #176091;
    border-radius: 8px;
    outline: none;
    color: white;
    height: 36px;
    border: none;
    font-size: 12px;
}

.rate_main {
    height: 56vh;
    overflow-y: scroll;
}


.rate_l {
    font-weight: 500;
    font-size: 16px;
    color: #545454;
    text-align: left;
}

.rate_l_m1 {
    font-weight: 500;
    font-size: 16px;
    color: #545454;
    text-align: left;
    position: relative;
    left: -3.15rem;
}

.rate_l_m2 {
    font-weight: 500;
    font-size: 16px;
    color: #545454;
    text-align: left;
    position: relative;
    left: -3.5rem;
}

.rate_l_m3 {
    font-weight: 500;
    font-size: 16px;
    color: #545454;
    text-align: left;
    position: relative;
    left: -5.4rem;
}

.rate_l_m4 {
    font-weight: 500;
    font-size: 16px;
    color: #545454;
    text-align: left;
    position: relative;
    left: -6.7rem;
}

.rate_l_m1_pre {
    font-weight: 500;
    font-size: 16px;
    color: #545454;
    text-align: left;
    position: relative;
    left: -3rem;
}

.rate_l_m2_pre {
    font-weight: 500;
    font-size: 16px;
    color: #545454;
    text-align: left;
    position: relative;
    left: -5.5rem;
}

.rate_l_m3_pre {
    font-weight: 500;
    font-size: 16px;
    color: #545454;
    text-align: left;
    position: relative;
    left: -7.5rem;
}

.rate_l_m4_pre {
    font-weight: 500;
    font-size: 16px;
    color: #545454;
    text-align: left;
    position: relative;
    left: -7rem;
}

.rate_l2 {
    font-weight: 500;
    font-size: 16px;
    color: #545454;
    text-align: left;
    margin-left: auto;
    position: relative;
    left: -8rem;
}

.r_false2 {
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    width: max-content;
}

.r_desc {
    color: #888888;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.r_input {
    height: 32px;
    background: #FAFAFA;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    width: 110px;
    text-align: center;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}

.r_input2 {
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    width: 110px;
    text-align: center;
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    padding-top: 5px;
}

.rate_btn {
    background: #176091;
    border-radius: 8px;
    outline: none;
    color: white;
    height: 32px;
    border: none;
    font-size: 12px;
    margin-left: auto;
    margin-right: 40px;
    width: 130px;
    /* margin-top: 30px; */
    font-weight: 300;
}

.modal-rate {
    height: 61vh;
    overflow-y: scroll;
}

/* .modal-content, .modal-body {
    width: 63vw !important;
} */

.rate_btn1 {
    background: #FFFFFF;
    border: 1px solid #A7A7A7;
    border-radius: 8px;
    color: #888888;
    height: 35px;
    font-size: 16px;
    margin-top: 20px;
    width: 110px;
}

.rate_btn2 {
    background: #176091;
    border-radius: 8px;
    outline: none;
    color: white;
    height: 35px;
    border: none;
    font-size: 16px;
    margin-top: 20px;
    width: 110px;
}

::placeholder {
    color: #888888;
}

.list_start2 {
    font-weight: 400;
    font-size: 16px;
    color: #444444;
}

.list_start_head {
    font-weight: 600;
    font-size: 18px;
    color: #000000;
}

.ven_btn2 {
    width: 52.5%;
    background: #176091;
    border-radius: 8px;
    outline: none;
    color: white;
    height: 48px;
    border: none;
    font-size: 16px;
}

.final-ven-head {
    /* border: 1px solid #DFDFDF; */
    border-radius: 4px;
    height: 56px;
}

.list_head2 {
    font-weight: 600;
    font-size: 20px;
    color: #000000;
}

.ven-edit {
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    color: #176091;
}

.final-ven-main {
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    /* height: 50vh; */
}

.ven_circ {
    background: #1EA866;
    height: 6px;
    width: 5px;
    border-radius: 10px;
    align-self: center;
    margin-top: -6px;
}

.ven_status {
    color: #888888;
    font-size: 16px;
    font-weight: 500;
}

.list_head3 {
    font-weight: 600;
    font-size: 20px;
    color: #199669;
    margin-left: auto;
}

.ven-data {
    font-weight: 400;
    font-size: 16px;
    color: #888888;
}

.ven-final-btn {
    color: #888888;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    height: 36px;
    width: 23%;
}

.ven-invoice {
    font-weight: 500;
    font-size: 16px;
    text-decoration-line: underline;
    color: #3B5998;
    margin-left: auto;
    cursor: pointer;
}

/* mobile */
.final-ven-head2 {
    border: none;
    border-radius: 4px;
    height: 50px;
    background: white;
}

.ven-data2 {
    font-weight: 400;
    font-size: 13px;
    color: #888888;
}

.ven-invoice2 {
    font-weight: 500;
    font-size: 16px;
    text-decoration-line: underline;
    color: #3B5998;
    cursor: pointer;
}

.r-sno1 {
    font-weight: 500;
    font-size: 12px;
    color: #545454;
    text-align: left;
}

.r-sno2 {
    font-weight: 500;
    font-size: 12px;
    color: #545454;
    text-align: left;
    position: relative;
    left: -2rem;
}

.r-sno2_m {
    font-weight: 500;
    font-size: 12px;
    color: #545454;
    text-align: left;
    position: relative;
    left: -0.8rem;
}

.r-sno3 {
    font-weight: 500;
    font-size: 12px;
    color: #545454;
    text-align: left;
    position: relative;
    left: -2rem;
}

.r-sno4 {
    font-weight: 500;
    font-size: 12px;
    color: #545454;
    text-align: left;
    position: relative;
    left: -3.5rem;
}

.r_false3 {
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    /* text-align: center; */
}

.r_input4 {
    height: 30px;
    background: #FAFAFA;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    width: 70px;
    text-align: center;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    margin-right: 11px;
}

.rate_btn4 {
    background: #176091;
    border-radius: 8px;
    outline: none;
    color: white;
    height: 40px;
    border: none;
    font-size: 14px;
    width: 100%;
    margin-top: 24px;
}

.rate_main2 {
    height: 55vh;
    overflow-y: scroll;
}

.rate_main3 {
    height: 62vh;
    overflow-y: scroll;
}
/* 
::-webkit-scrollbar {
    display: block;
} */

@media screen and (max-width: 992px) {
    .offcanvas-header {
        border-bottom: none;
        margin-bottom: 0 !important;
    }
}

@media screen and (max-width: 800px) {
    .modal-content, .modal-body {
        width: 95vw;
    }
}

.r_input6 {
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    width: 70px;
    text-align: center;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    padding-top: 3.5px;
    margin-right: 11px;
}

/* designer vendor page */

.des-ven-start {
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    cursor: pointer;
}

.des-ven-start22 {
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    cursor: pointer;
    background: RGB(243 247 249);
}

.des-ven-p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.des-ven-s {
    font-weight: 400;
    font-size: 13px;
    color: #888888;
}

.des-ven-end {
    font-weight: 500;
    font-size: 14px;
    color: #176091;
    margin-left: auto;
}

.des-ven-start2 {
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    background: #888888;
}

.ven-img {
    width: 93px;
    height: 103.4px;
}


.ven-side {
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    margin-top: 5.5rem;
    width: 28%;
}

.ven-side2 {
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    margin-top: 5.5rem;
    width: 68%;
}

.ven-pro {
    width: 54px;
    height: 54px;
    border-radius: 4rem;
    border: 1px solid #DFDFDF
}

.des-ven-p2 {
    font-weight: 500;
    font-size: 16px;
    /* line-height: 24px; */
    color: #000000;
    align-self: center;
    text-align: center;
}

.input-blah {
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    height: 48px;
    padding-left: 6px;
}

.des-ven-blah {
    border-bottom: 1px solid #DFDFDF;
    width: 90%;
}

.ven-vlah-vlah {
    font-weight: 400;
    font-size: 14px;
    color: #176091;
    border: 1px solid #176091;
    background: white;
    border-radius: 4px;
    width: 11rem;
    height: 44px;
}

.mob-ven-blah {
    font-weight: 400;
    font-size: 14px;
    color: #176091;
    margin-top: auto;
}

.ven-edit-list {
    /* border: 1px solid #DFDFDF; */
    border-radius: 4px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.vendor-main-start-final {
    border: 1px solid #DFDFDF;
    border-radius: 10px;
}

.img-vendor {
    background: rgba(59, 89, 152, 0.1);
    border-radius: 4px;
}

.r-info {
    align-self: flex-start;
    /* margin-top: 3px;
    position: relative;
    left: -12px; */
    height: 14px;
    width: 14px;
    margin-left: 3px;
}

.vendor-header-price {
    font-weight: 400;
    font-size: 22px;
    color: #000000;

}

.vendor-header-price2 {
    font-weight: 600;
    font-size: 22px;
    color: #000000;
    margin-left: auto;
}

.ven-price-span {
    font-weight: 400;
    font-size: 13px;
    color: #888888;
}

.vendor-cart-remove {
    align-self: baseline;
    font-weight: 500;
    font-size: 16px;
    color: #888888;
    border: none;
    background: none;
    margin-left: auto;
    /* padding-top: 15px; */
}

.vendor-cart-start {
    border: 1px solid #DFDFDF;
    margin-top: 1rem;
    padding: 0.5rem;
}

.landing-ven-heder {
    background: #D1DFE9;
    width: 36px;
    height: 36px;
    border-radius: 100px;
    text-align: center;
    padding-top: 7px;
    color: #176091;
    font-weight: 350;
    font-size: 14px;
    margin-bottom: 1.5rem;
}

.lan-ven-start {
    font-weight: 500;
    font-size: 15px;
    color: #000000;
}

.lan-ven-start2 {
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    color: #888888;
    line-height: 16px;
}

.ven-blah-blah {
    font-weight: 600;
    font-size: 18px;
    color: #000000;
}

@media screen and (max-width: 768px) {
    .vendor-cart-start {
        border: none;
        box-shadow: rgb(0 0 0 / 10%) 0px 4px 10px;
        margin-top: 30px !important;
        padding: 1rem;
    }

    .vendor-header-price {
        font-weight: 500;
        font-size: 16px;
    }

    .vendor-header-price2 {
        font-size: 16px;
    }

    .ven-price-span {
        font-size: 11px;
        margin-left: -14px;
    }

    .img-vendor2 {
        height: 30px;
        width: 30px;
    }

    .img-vendor {
        height: 21%
    }

    .vendor-cart-remove {
        border-top: 1px solid #DFDFDF;
        padding-top: 0;
        align-self: center;
        width: 100%;
        margin-top: 8px;
        padding-top: 6px;
    }
}

.ven-free {
    background: rgba(23, 96, 145, 0.1);
    border-radius: 4px;
}

.ven-blah2 {
    font-weight: 400;
    font-size: 14px;
    color: #176091;
}

.no-content {
    height: 100%;
    margin: auto;
    align-items: center;
    display: flex;
}

/* .claasname::-webkit-scrollbar {
    display: none;
} */