.container {
  padding: 1rem;
  display: flex;
  /* border: 2px solid red; */
}

.threeBtnDiv1 {
  display: flex;
  justify-content: space-between;
  background: #f2f4f8;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #71a5de;
  width: 100%;
  padding: 0.6rem 1rem 0.6rem 1rem;
}
.threeBtnDiv2 {
  display: flex;
  justify-content: space-between;
  background: #fffae5;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #f5b82e;
  width: 100%;
  padding: 0.6rem 1rem 0.6rem 1rem;
}
.threeBtnDiv3 {
  display: flex;
  justify-content: space-between;
  background: #def8ed;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #26ad74;
  /* width: 100%; */

  padding: 0.6rem 1rem 0.6rem 1rem;
}

.fileDiv {
  background: #f7f7f7;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin-top: 1rem;
}

.pending {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.200264px;
  color: #000000;
  padding-right: 1rem;
}

.fileText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13.5px;
  line-height: 15px;
  display: flex;
  /* align-items: center; */

  letter-spacing: 0.104348px;
  color: #212121;
}
.fileText1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13.5px;
  line-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  letter-spacing: 0.104348px;
  color: #212121;
  /* border: 2px solid red; */
}

.date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.104348px;
  color: #848080;
}

.fileSum {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #000000;
  margin-left: 1rem;
}
