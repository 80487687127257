.container {
    background-color: #ebf7f7;
    padding-top: 3rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #212121;
  }
  .leftImg{
      width: 100%;
      /* border: 2px solid red; */
  }
  .rightImg{
      width: 100%;
  }
  