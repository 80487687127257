.footNavContainer {
  height: 18vh;
  background-color: #ffffff;
}
.footNavContainerWithoutBanner {
  height: 11vh;
  padding-top: 0.5rem;
  background-color: #ffffff;
  border-top: 1px solid #dfdfdf;
}

.advertisement {
  background: linear-gradient(90deg, #6c60b6 2.08%, #335386 100%);
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
  padding: 0.7rem 1rem;
}

.advertLeftPart {
  display: flex;
}
.advertLeftPart button {
  background-color: #ffffff00;
  color: #ffffff;
  border-radius: 4px;
  border: 1px solid #ffffff;
  padding: 0 0.5rem;
  margin-left: 0.5rem;
  font-size: 10px;
}

.navIconsContainer {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}
.eachNavIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.eachNavIconActive {
  color: #3b5998;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconContainer {
  background-color: #e2e6f0;
  border-radius: 12px;
  padding: 0.1rem 0.8rem;
}
.iconContainerInactive {
  background-color: #ffffff;
}
