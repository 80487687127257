.container {
  height: 100vh;
  display: flex;
}
.first_contain {
  background-color: #e9effb;
  height: 100vh;
  width: 50%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
}

.ImgDiv {
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #E9EFFB;

}
.imgg{
  width: 90%;
}

.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 127.52%;
  color: #1d3557;
  margin-top: 1rem;
}
.para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17.7px;
  line-height: 25px;
  color: #212121;
  margin-top: 0.5rem;
  /* border: 2px solid red; */
  padding-right: 2rem;
}
.icon {
  margin-top: 2rem;
  height: 1.5rem;
  width: 1.5rem;
}

/* second div */
.second_contain {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
}
.PersonalContent_div {
  /* border: 2px solid red; */
  /* height: 60%; */
  width: 90%;
  display: flex;
  flex-direction: column;
  padding-left: 5rem;
  padding-top: 2rem;
}
.personal_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 44px;
  color: #212121;
}
.inputs {
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.8rem 1rem 0.8rem 1rem;
  width: 80%;
  margin-top: 1.5rem;
  color: #565353;
  font-size: 16px;
}
.inputsDrop{
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.4rem 1rem 0.4rem 0.4rem;
  width: 80%;
  margin-top: 1.5rem;
  font-size: 16px;

}
.inputsErr {
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.8rem 1rem 0.8rem 1rem;
  width: 80%;
  margin-top: 1.5rem;
  border: 2px solid rgb(190, 48, 48);
  font-size: 16px;
}
.inputsErrDrop {
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.4rem 1rem 0.4rem 0.4rem;
  width: 80%;
  margin-top: 1.5rem;
  border: 2px solid rgb(190, 48, 48);
  font-size: 16px;
}
.inputs::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #4d4d4d;
  font-size: 16px;
}
.btn{
  background: #2053c5;
  border-radius: 10px;
  border: none;
  padding: 0.9rem 1rem 0.9rem 1rem;
  width: 80%;
  margin-top: 2rem;
  color: #ffffff;
}
.backBtn {
  background: #f6f8fe;
  /* border-radius: 50px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #2053c5;
  border: none;
  border-radius: 14px;
   padding: 0.2rem 0.8rem 0.2rem 0.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.backArrow{
    margin-right: 0.5rem;
}

.skip {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #b7b8bb;
  display: flex;
  justify-content: center;
  /* border: 2px solid red; */
  width: 80%;
  margin-top: 1rem;
  cursor: pointer;
}
