.container {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
  background-color: #ffffff;
  border-radius: 12px 12px 0px 0px;
}

.btns {
  border: 2px solid #093c6c;
  border-radius: 50px;
  background-color: #ffffff;
  padding: 0.4rem 1rem 0.4rem 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #093c6c;
}
.btnsActive {
  border: 2px solid #093c6c;
  border-radius: 50px;
  background-color: #093c6c;
  padding: 0.4rem 1rem 0.4rem 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
}
