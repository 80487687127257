.container {
  /* border: 2px solid red; */
  margin-top: 2rem;
  padding-left: 3rem;
  padding-right: 2rem;
  padding-bottom: 5rem;
}

.inputTopDiv {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.topInput {
  background: #ffffff;
  border: 1px solid rgba(33, 33, 33, 0.6);
  border-radius: 10px;
  padding: 0.5rem 2rem 0.5rem 1rem;
}
.topInputActive{
  background: #ffffff;
  border: 2px solid rgba(33, 33, 33, 0.6);
  border-radius: 10px;
  padding: 0.5rem 2rem 0.5rem 1rem;

}

.names {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #252525;
  padding-bottom: 0.5rem;
}

.restInput{
    /* border: 2px solid red; */
   /* margin-top: 2rem; */
}
