.container {
  padding: 1rem;
  display: flex;
  margin-top: 2rem;
}

.fileDiv {
  background: #f7f7f7;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin-top: 1rem;
}

.pending {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.200264px;
  color: #000000;
}

.fileText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13.5px;
  line-height: 15px;
  display: flex;
  /* align-items: center; */

  letter-spacing: 0.104348px;
  color: #212121;
  width: 25rem;
  /* border: 2px solid red; */
}
.fileText1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  letter-spacing: 0.104348px;
  color: #4d4d4d;
  width: 20%;
  /* border: 2px solid red; */
}

.date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.104348px;
  color: #848080;
}
.cicularbar {
  width: 7rem !important;
  /* color: #FFBD70; */
  /* background-color: #FFBD70; */
  /* border: 2px solid red; */
}

.fileTextt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;
  letter-spacing: 0.200264px;
  padding-bottom: 2rem;
  color: #000000;
}

.filesmall {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.104348px;
  padding-bottom: 2rem;
  padding-right: 2rem;
  color: #252525;
}

.circularText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.104348px;
  margin-top: 1rem;

  color: #3d3d3d;
}
