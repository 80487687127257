.container {
  /* border: 2px solid red; */
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 15rem;
}
.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 58px;
  text-align: center;
  color: #2053c5;
}
.btn1 {
  background: #2053c5;
  border-radius: 8px;
  border: none;
  padding: 0.7rem 1.2rem 0.7rem 1.2rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
}

.btn2 {
  border: 1px solid #2053c5;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 0.7rem 1rem 0.7rem 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #2053c5;
}


